import Image from 'next/image';
import Link from 'next/link';

import cx from 'classnames';

type FooterProps = {
  className?: string;
  medicalCode?: string;
};

export default function Footer({
  className,
  medicalCode = 'IT - AGNA - 240285',
}: FooterProps) {
  return (
    <div
      className={cx(
        'w-full flex flex-col items-center pt-10 pb-10 bg-darkBrown',
        className
      )}
    >
      <Image
        src="/assets/logo-horizontal.svg"
        alt=""
        width={302}
        height={42}
        className="mb-8"
      />

      <p className="mx-4 mb-10 text-brown text-center">
        Per ricevere assistenza scrivi a&nbsp;
        <a
          href="mailto:infocoe@allergan.com"
          className="font-medium text-lightBrown"
        >
          infocoe@allergan.com
        </a>
      </p>

      <div className="flex gap-x-6 text-brown underline mb-9">
        <Link
          href="/terms"
          // target="_blank"
        >
          Termini e Condizioni
        </Link>
        <Link href="https://www.abbvie.it/privacy.html" target="_blank">
          Privacy Policy
        </Link>
      </div>

      <p className="text-brown text-center leading-8 text-lg">
        Piattaforma destinata alla classe medica
        <br />
      </p>
      <p className="text-brown">{medicalCode}</p>
    </div>
  );
}
